<template>
  <div class="content">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <card class="card p-3">
          <div class="row">
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.target") }} - {{ this.tahun }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #28A44A">
                    {{ this.totalTargetCountry | formatRp }}
                  </h2>
                </h4>
              </card>
            </div>
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.report") }} - {{ this.tahun }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #E53B3B">
                    {{ this.totalLaporanCountry | formatRp }}
                  </h2>
                </h4>
              </card>
            </div>
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.total_sp") }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #000000">
                    {{ this.totalSP }}
                  </h2>
                </h4>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.target") }} - {{ this.bulan }}
                      {{ this.tahun }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #28A44A">
                    {{ this.totalTargetBulanIni | formatRp }}
                  </h2>
                </h4>
              </card>
            </div>
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.report") }} - {{ this.bulan }}
                      {{ this.tahun }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #E53B3B">
                    {{ this.totalLaporanBulanIni | formatRp }}
                  </h2>
                </h4>
              </card>
            </div>
            <div class="col-lg-4 col-md-4 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.total_farmer") }}
                    </a>
                    <!-- <img
                      class="icon ml-1 mt-1"
                      src="@/assets/img/info-icon.png"
                      alt="info-icon"
                    /> -->
                  </template>
                  <h2 class="font-weight-bold mt-2" style="color: #000000">
                    {{ this.totalPetani }}
                  </h2>
                </h4>
              </card>
            </div>
          </div>
          <div class="row">
            <div
              class="col-lg-4 col-md-4 pr-1"
              style="min-height: 350px !important;"
            >
              <card
                class="card card-bordered mb-3"
                style="border-radius: 10px;"
              >
                <h4 slot="header" class="card-title">
                  <template>
                    <a class="font-weight-bold text-black text-ds-card">
                      {{ $t("dashboard.compare") }}
                    </a>
                    <tooltip
                      :content="$t('global.achv')"
                      class="ml-1 mt-1 float-right"
                    >
                      <template>
                        <img
                          class="icon"
                          src="@/assets/img/info-icon.png"
                          alt="info-icon"
                        />
                      </template>
                    </tooltip>
                  </template>
                </h4>
                <ChartPerbandingan :series="[countSeriesPerbandingan()]" />
              </card>
            </div>
            <div class="col-lg-8 col-md-8 pr-1">
              <card class="card card-bordered mb-3" style="border-radius: 10px">
                <h4 slot="header" class="card-title">
                  <template>
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <a class="font-weight-bold text-black text-ds-card">
                          {{ $t("dashboard.stat") }}
                        </a>
                      </div>
                      <div :hidden="true" class="col-lg-6 col-md-6 float-right">
                        <form>
                          <select
                            :hidden="!isTahun"
                            class="w-25 ml-2 float-right form-control"
                            v-model="SelectedTahun"
                          >
                            <option :value="parseInt(this.tahun) - 3">{{
                              parseInt(this.tahun) - 3
                            }}</option>
                            <option :value="parseInt(this.tahun) - 2">{{
                              parseInt(this.tahun) - 2
                            }}</option>
                            <option :value="parseInt(this.tahun) - 1">{{
                              parseInt(this.tahun) - 1
                            }}</option>
                            <option :value="parseInt(this.tahun)" selected>{{
                              parseInt(this.tahun)
                            }}</option>
                          </select>
                          <select
                            :hidden="!isBulan"
                            class="w-25 ml-2 float-right form-control"
                            v-model="SelectedBulan"
                          >
                            <option
                              v-for="data in DataBulan"
                              :key="data.id"
                              :value="data.value"
                              >{{ data.value }}
                            </option>
                          </select>
                          <select
                            v-model="SelectedType"
                            @change="SelectType"
                            class="w-25 float-right form-control"
                          >
                            <option value="Tahunan" selected>Tahunan</option>
                            <option value="Bulanan">Bulanan</option>
                          </select>
                        </form>
                      </div>
                    </div>
                  </template>
                </h4>
                <apexchart
                  type="bar"
                  :options="chartPencapaianOptions"
                  :series="seriesPencapaian"
                ></apexchart>
              </card>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Card } from "@/components/index";
import { Q_LIST_FARMER, Q_LIST_REPORT, Q_LIST_TARGET } from "@/graphql/tss1";
import { RoleType } from "@/graphql/enum.type.js";
import moment from "moment";
import ChartPerbandingan from "@/components/Charts/Perbandingan.vue";

import VueApexCharts from "vue-apexcharts";
import { Q_LIST_USER1 } from "@/graphql/sa";
import jwtService from "@/core/services/jwt.service";

export default {
  components: {
    Card,
    apexchart: VueApexCharts,
    ChartPerbandingan
  },
  data() {
    return {
      countryID: [],
      regionID: [],
      provID: [],
      kabID: [],
      dataTarget: [],
      dataLaporan: [],
      totalTargetCountry: "",
      totalTargetBulanIni: "",
      totalLaporanCountry: "",
      totalLaporanBulanIni: "",
      bulan: "",
      isBulan: false,
      isTahun: true,
      tahun: moment()
        .format("YYYY")
        .toString(),
      totalSP: "",
      totalPetani: "",
      SelectedType: "",
      SelectedTahun: "",
      SelectedBulan: "",
      DataBulan: [
        { value: "April" },
        { value: "Mei" },
        { value: "Juni" },
        { value: "Juli" },
        { value: "Agustus" },
        { value: "September" },
        { value: "Oktober" },
        { value: "November" },
        { value: "Desember" },
        { value: "Januari" },
        { value: "Februari" },
        { value: "Maret" }
      ],
      chartPencapaianOptions: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true
            }
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "80%"
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return `${value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
            }
          }
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: val =>
              `Rp ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
          }
        },
        fill: {
          colors: ["#28A44A", "#FD8833"]
        },
        colors: ["#28A44A", "#FD8833"]
      },
      seriesPencapaian: [],
      seriesPerbandingan: []
    };
  },
  apollo: {
    listTarget: {
      query: () => Q_LIST_TARGET,
      variables() {
        return {
          page: 1,
          limit: 999999,
          keyword: "",
          types: null,
          from: "",
          to: jwtService.getID(),
          tahun: this.tahun,
          bulan: "",
          country_id: [],
          region_id: [],
          provinsi: this.getProvinceID(),
          kabupatens: [],
          kecamatans: [],
          kelurahans: [],
          produk_id: []
        };
      },
      result({ data }) {
        this.dataTarget = data.listTarget.targets;
        this.CountTarget();
      },
      error(e) {
        this.catchError(e);
      }
    },
    listLaporan: {
      query: () => Q_LIST_REPORT,
      variables() {
        return {
          page: 1,
          limit: 999999999,
          from: [jwtService.getID()],
          to: "",
          target_id: "",
          country_id: [],
          region_id: [],
          prov_id: this.getProvinceID(),
          kab_id: [],
          kec_id: [],
          kel_id: [],
          r1: [],
          r2: [],
          dis: [],
          petani_id: [],
          produk_id: [],
          year: this.tahun,
          month: "",
          keyword: "",
          start: "",
          end: ""
        };
      },
      result({ data }) {
        this.dataLaporan = data.listLaporan.laporans;
        this.CountLaporan();
      }
    },
    listPetani: {
      query: () => Q_LIST_FARMER,
      variables() {
        return {
          pages: 1,
          limit: 9999999,
          keyword: "",
          sp_id: [],
          tss_id: [],
          country_id: [],
          region_id: [],
          prov_id: this.getProvinceID(),
          kab_id: []
        };
      },
      result({ data }) {
        this.totalPetani = data.listPetani.total;
      }
    },
    listUser: {
      query: () => Q_LIST_USER1,
      variables() {
        return {
          page: 1,
          limit: 99999999,
          keyword: "",
          isActive: null,
          country_id: [],
          region_id: [],
          prov_id: this.getProvinceID(),
          kabupaten_id: [],
          kecamatan_id: [],
          kelurahan_id: [],
          tss_id: [],
          role: RoleType.SP
        };
      },
      result({ data }) {
        this.totalSP = data.listUser.total;
      }
    }
  },
  methods: {
    countSeriesPerbandingan() {
      const res = Math.ceil(
        (this.seriesPerbandingan[0] / this.seriesPerbandingan[1]) * 100
      );
      if (!isNaN(res)) {
        if (res > 100) {
          return 100;
        } else {
          return res;
        }
      } else {
        return 0;
      }
    },
    CountTarget() {
      const ANNUAL_DATA = [];
      for (let i = 0; i < this.dataTarget.length; i++) {
        if (
          this.dataTarget[i].tipe_target === "ANNUAL" &&
          this.dataTarget[i].target_value !== ""
        ) {
          ANNUAL_DATA.push(this.dataTarget[i]);
        }
      }

      //
      this.totalTargetCountry = ANNUAL_DATA.reduce((val, element) => {
        return val + parseInt(element.target_value);
      }, 0);

      this.bulan = this.ConvertBulan(moment().format("MMMM"));
      const DATA_BULAN_INI = [];
      const FilterTarget = [];
      this.dataTarget.map(items => {
        if (
          items.month === this.bulan &&
          items.month != null &&
          items.target_value !== ""
        ) {
          DATA_BULAN_INI.push(items);
        }
      });
      // console.log(DATA_BULAN_INI);
      this.totalTargetBulanIni = DATA_BULAN_INI.reduce((val, element) => {
        return val + parseInt(element.target_value);
      }, 0);
      // eslint-disable-next-line no-empty
      if (this.seriesPerbandingan.length === 2) {
      } else {
        this.seriesPerbandingan.push(this.totalTargetCountry);
      }
      this.DataBulan.map(items => {
        // eslint-disable-next-line no-empty
        if (this.chartPencapaianOptions.xaxis.categories.length >= 12) {
        } else {
          this.chartPencapaianOptions.xaxis.categories.push(
            items.value.substring(0, 3).toUpperCase()
          );
        }
        const result = this.dataTarget.filter(
          val =>
            val.year === this.tahun &&
            val.month === items.value &&
            val.target_value !== ""
        );
        // NamaBulan.push(items.value);
        const count = result.reduce((val, element) => {
          return val + parseInt(element.target_value);
        }, 0);
        FilterTarget.push(count);
      });
      // eslint-disable-next-line no-empty
      if (this.seriesPencapaian.length >= 2) {
      } else {
        this.seriesPencapaian.push({
          name: "Total Target",
          data: FilterTarget
        });
      }
    },
    CountLaporan() {
      const ANNUAL_DATA = [];
      this.dataLaporan.map(items => {
        if (items.year === this.tahun) {
          ANNUAL_DATA.push(items);
        }
      });
      // console.log(ANNUAL_DATA);
      this.totalLaporanCountry = ANNUAL_DATA.reduce((val, element) => {
        return val + parseInt(element.value);
      }, 0);

      this.bulan = this.ConvertBulan(moment().format("MMMM"));
      const DATA_BULAN_INI = [];
      this.dataLaporan.map(items => {
        if (items.month === this.bulan && items.month != null) {
          DATA_BULAN_INI.push(items);
        }
      });

      this.totalLaporanBulanIni = DATA_BULAN_INI.reduce((val, element) => {
        return val + parseInt(element.value);
      }, 0);

      // eslint-disable-next-line no-empty
      if (this.seriesPerbandingan.length === 2) {
      } else {
        this.seriesPerbandingan.push(this.totalLaporanCountry);
      }
      const FILTER_LAPORAN = [];
      this.DataBulan.map(items => {
        // eslint-disable-next-line no-empty
        if (this.chartPencapaianOptions.xaxis.categories.length >= 12) {
        } else {
          this.chartPencapaianOptions.xaxis.categories.push(
            items.value.substring(0, 3).toUpperCase()
          );
        }
        const resultCapai = this.dataLaporan.filter(
          val => val.year === this.tahun && val.month === items.value
        );
        const countCapai = resultCapai.reduce((val, element) => {
          return val + parseInt(element.value);
        }, 0);
        FILTER_LAPORAN.push(countCapai);
      });
      // eslint-disable-next-line no-empty
      if (this.seriesPencapaian.length >= 2) {
      } else {
        this.seriesPencapaian.push({
          name: "Total Capaian",
          data: FILTER_LAPORAN
        });
      }
    },
    async LoadAwal() {
      await this.findDataTarget();
      await this.findDataLaporan();
      await this.findAndcountSP();
      await this.findAndcountPetani();
      this.CountTarget();
      this.CountLaporan();
      this.ChartPencapaian();
    },
    ConvertBulan(value) {
      let Bulan;
      switch (value) {
        case "January":
          Bulan = "Januari";
          break;
        case "February":
          Bulan = "Februari";
          break;
        case "March":
          Bulan = "Maret";
          break;
        case "April":
          Bulan = "April";
          break;
        case "May":
          Bulan = "Mei";
          break;
        case "June":
          Bulan = "Juni";
          break;
        case "July":
          Bulan = "Juli";
          break;
        case "August":
          Bulan = "Agustus";
          break;
        case "September":
          Bulan = "September";
          break;
        case "October":
          Bulan = "Oktober";
          break;
        case "November":
          Bulan = "November";
          break;
        case "December":
          Bulan = "Desember";
          break;
      }
      return Bulan;
    },
    SelectType() {
      if (this.SelectedType === "Tahunan") {
        this.isTahun = true;
        this.isBulan = false;
        this.SelectedBulan = "";
      } else if (this.SelectedType === "Bulanan") {
        this.isTahun = false;
        this.isBulan = true;
        this.SelectedTahun = "";
      }
    }
  },
  mounted() {},
  beforeMount() {
    this.seriesPerbandingan = [];
    this.chartPencapaianOptions.xaxis.categories = [];
    this.seriesPencapaian = [];
  },
  beforeDestroy() {}
};
</script>
<style></style>
