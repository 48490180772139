// PETANI
import gql from "graphql-tag";

export const Q_LIST_FARMER = gql`
  query listPetani(
    $keyword: String
    $pages: Int
    $limit: Int
    $sp_id: [String!]
    $tss_id: [String!]
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
  ) {
    listPetani(
      listPetaniInput: {
        page: $pages
        limit: $limit
        order: { sortBy: DESC, orderBy: CREATED_AT }
        search: {
          keyword: $keyword
          sp_id: $sp_id
          tss_id: $tss_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
        }
      }
    ) {
      page
      totalPage
      total
      petanis {
        id
        name
        sp {
          id
          name
        }
        tss {
          id
          name
        }
        kab_id
        kec {
          id
          kecamatan
        }
        kel {
          id
          kelurahan
        }
        luas
        alamat
        hp
        ucrops {
          id
          crop
        }
        ocrops {
          id
          crop
        }
      }
    }
  }
`;

// LAPORAN
export const Q_LIST_REPORT = gql`
  query listLaporan(
    $page: Int
    $limit: Int
    $keyword: String
    $from: [String!]
    $to: String
    $role: roleType
    $target_id: String
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $r1: [Int!]
    $r2: [Int!]
    $dis: [Int!]
    $petani_id: [String!]
    $produk_id: [String!]
    $year: String
    $month: String
    $start: DateTime
    $end: DateTime
  ) {
    listLaporan(
      listLaporanInput: {
        page: $page
        limit: $limit
        order: { sortBy: DESC, orderBy: CREATED_AT }
        search: {
          keyword: $keyword
          from: $from
          to: $to
          role: $role
          target_id: $target_id
          country_id: $country_id
          region_id: $region_id
          prov_id: $prov_id
          kab_id: $kab_id
          kec_id: $kec_id
          kel_id: $kel_id
          r1: $r1
          r2: $r2
          dis: $dis
          petani_id: $petani_id
          produk_id: $produk_id
          year: $year
          month: $month
          likR1: null
          likR2: null
          start_date: $start
          end_date: $end
        }
      }
    ) {
      page
      total
      totalPage
      laporans {
        id
        day_report
        month
        year
        froms {
          id
          name
        }
        tos {
          id
          name
        }
        produknames {
          id
          brand
        }
        price
        quantity
        value
        countrys {
          country
        }
        regions {
          region
        }
        provs {
          province
        }
        kabs {
          kabupaten
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        distributors {
          id
          name
        }
        invoice
      }
    }
  }
`;

export const Q_LIST_TARGET = gql`
  query listTarget(
    $page: Int
    $limit: Int
    $keyword: String
    $types: SalesTargetType
    $from: String
    $to: String
    $role: roleType
    $tahun: String
    $bulan: String
    $country_id: [ID!]
    $region_id: [ID!]
    $provinsi: [String!]
    $kabupatens: [String!]
    $kecamatans: [String!]
    $kelurahans: [String!]
    $produk_id: [String!]
  ) {
    listTarget(
      listTargetInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          types: $types
          from: $from
          to: $to
          role: $role
          tahun: $tahun
          bulan: $bulan
          country_id: $country_id
          region_id: $region_id
          provinsi: $provinsi
          kabupatens: $kabupatens
          kecamatans: $kecamatans
          kelurahans: $kelurahans
          produk_id: $produk_id
        }
      }
    ) {
      targets {
        id
        froms {
          id
          name
        }
        tos {
          id
          name
        }
        year
        month
        countrys {
          country
        }
        regions {
          region
        }
        provs {
          id
          province
        }
        kabs {
          id
          kabupaten
        }
        kecamatans {
          id
          kecamatan
        }
        kelurahans {
          id
          kelurahan
        }
        retailers1 {
          id
          name
        }
        retailers2 {
          id
          name
        }
        produknames {
          id
          brand
        }
        price
        tipe_target
        target_quantity
        target_value
        isReported
        tipe_target
      }
    }
  }
`;

export const Q_LIST_USER = gql`
  query listUser(
    $page: Int
    $limit: Int
    $keyword: String
    $isActive: Boolean
    $country_id: [Int!]
    $region_id: [Int!]
    $province_id: [String!]
    $kabupaten_id: [String!]
    $kecamatan_id: [String!]
    $kelurahan_id: [String!]
    $tss_id: [String!]
    $role: roleType
  ) {
    listUser(
      listUserInput: {
        search: {
          isActive: $isActive
          keyword: $keyword
          region_id: $region_id
          country_id: $country_id
          province_id: $province_id
          kabupaten_id: $kabupaten_id
          kecamatan_id: $kecamatan_id
          kelurahan_id: $kelurahan_id
          tss_id: $tss_id
          role: $role
        }
        limit: $limit
        page: $page
      }
    ) {
      total
      totalPage
      users {
        id
        name
        email
        phone
        ugdn
        role
        isActive
        detail {
          countrys {
            id
            country
          }
          regions {
            id
            region
          }
          province_id
          province {
            id
            province
          }
          kabupaten {
            id
            kabupaten
          }
          groups {
            id
            group
          }
          tss {
            id
            name
          }
        }
      }
    }
  }
`;

export const Q_LIST_USER1 = gql`
  query listUserBsc(
    $page: Int
    $limit: Int
    $keyword: String
    $role: roleType
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $tss_id: [String!]
  ) {
    listUser(
      listUserInput: {
        page: $page
        limit: $limit
        search: {
          keyword: $keyword
          role: $role
          country_id: $country_id
          region_id: $region_id
          province_id: $prov_id
          kabupaten_id: $kab_id
          kecamatan_id: $kec_id
          kelurahan_id: $kel_id
          tss_id: $tss_id
        }
        order: { orderBy: ROLE, sortBy: ASC }
      }
    ) {
      page
      total
      totalPage
      users {
        id
        ugdn
        email
        phone
        name
        role
        isActive
      }
    }
  }
`;

export const Q_LENGTH_USER = gql`
  query lengthUser(
    $role: roleType
    $country_id: [Int!]
    $region_id: [Int!]
    $prov_id: [String!]
    $kab_id: [String!]
    $kec_id: [String!]
    $kel_id: [String!]
    $tss_id: [String!]
  ) {
    listUser(
      listUserInput: {
        page: 1
        limit: 99999
        search: {
          keyword: ""
          role: $role
          isActive: true
          country_id: $country_id
          region_id: $region_id
          province_id: $prov_id
          kabupaten_id: $kab_id
          kecamatan_id: $kec_id
          kelurahan_id: $kel_id
          tss_id: $tss_id
        }
      }
    ) {
      total
    }
  }
`;

export const M_ADD_SP = gql`
  mutation addSP(
    $name: String!
    $email: String!
    $phone: String!
    $ugdn: String
    $role: roleType!
    $detail: [CreateDetailUserInput!]
  ) {
    createUser(
      createUserInput: {
        name: $name
        email: $email
        phone: $phone
        ugdn: $ugdn
        role: $role
        detail: $detail
      }
    ) {
      id
      name
    }
  }
`;
export const M_EDIT_SP = gql`
  mutation editUser(
    $id: ID!
    $name: String!
    $email: String!
    $phone: String!
    $ugdn: String
    $role: roleType!
    $detail: [CreateDetailUserInput!]
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        name: $name
        email: $email
        phone: $phone
        ugdn: $ugdn
        role: $role
        detail: $detail
      }
    ) {
      id
      name
    }
  }
`;

export const Q_DETAIL_USER = gql`
  query detailUser($id: ID!) {
    detailUser(id: $id) {
      id
      detail {
        countrys {
          id
          country
        }
        regions {
          id
          region
        }
        province {
          id
          province
        }
        kabupaten {
          id
          kabupaten
        }
        kecamatan {
          id
          kecamatan
        }
        kelurahan {
          id
          kelurahan
        }
      }
    }
  }
`;

export const M_PUBLISH_USER = gql`
  mutation publishUser($id: String!, $isActive: Boolean!) {
    publishUser(publishUserInput: { id: $id, isActive: $isActive }) {
      id
    }
  }
`;

export const Q_LIST_OUTSTANDING = gql`
  query listOutstanding(
    $page: Int
    $limit: Int
    $keyword: String
    $from: String
    $to: String
    $region_id: [Int!]
    $isSaw: Boolean
  ) {
    listOutstanding(
      listOutstandingInput: {
        search: {
          keyword: $keyword
          from: $from
          to: $to
          region_id: $region_id
          isSaw: $isSaw
        }
        order: { orderBy: CREATED_AT, sortBy: DESC }
        limit: $limit
        page: $page
      }
    ) {
      total
      page
      totalPage
      outstandings {
        id
        froms {
          id
          name
          role
        }
        tos {
          id
          name
          role
        }
        regions {
          id
          region
        }
        region_id
        file
        isSaw
        feedback
      }
    }
  }
`;
export const M_ADD_OUTSTANDING = gql`
  mutation createOutstanding(
    $from: String!
    $to: String!
    $region_id: Int
    $file: String
  ) {
    createOutstanding(
      createOutstandingInput: {
        from: $from
        to: $to
        region_id: $region_id
        file: $file
      }
    ) {
      id
      froms {
        id
        name
        role
      }
      tos {
        id
        name
        role
      }
      file
      isSaw
      feedback
    }
  }
`;
export const M_EDIT_OUTSTANDING = gql`
  mutation updateOutstanding(
    $id: ID!
    $from: String!
    $to: String!
    $region_id: Int
    $dokumentasi: String
  ) {
    updateOutstanding(
      updateOutstandingInput: {
        id: $id
        from: $from
        to: $to
        region_id: $region_id
        dokumentasi: $dokumentasi
      }
    ) {
      id
      froms {
        id
        name
        role
      }
      tos {
        id
        name
        role
      }
      file
      isSaw
      feedback
    }
  }
`;
