<template>
  <div id="chart">
    <apexchart
      type="radialBar"
      height="300"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    series: {
      type: Array
    }
  },
  data() {
    return {
      // series: [70],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20
        },
        plotOptions: {
          radialBar: {
            track: {
              background: "#E9F6ED",
              strokeWidth: "97%",
              margin: 5
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 10,
                fontSize: "40px",
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight: 600
              }
            }
          }
        },
        stroke: {
          lineCap: "round"
        },
        fill: {
          type: "solid",
          colors: ["#28A44A"]
        },
        labels: ["Average Results"]
      }
    };
  }
};
</script>
